<template>
  <div>
    <dashboard-page-title :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">{{ $t('auth.transactions') }}</dashboard-page-title>

    <main-table :fields="fields" :baseURL="SFZ_API_LINK" :list_url="'admin/wallets/transactions'"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SFZ_API_LINK } from '@/config/constant'
export default {
  name: 'transactions',
  mounted () {
    core.index()
  },
  data () {
    return {
      SFZ_API_LINK,
      fields: [
        { label: 'الصورة', key: 'user.avatar', type: 'image', class: 'text-right' },
        { label: 'الاسم', key: 'user.name', type: 'text', class: 'text-right' },
        { label: 'رقم العملية', key: 'transaction_id', type: 'name', class: 'text-right' },
        { label: 'الكمية', key: 'amount', class: 'text-right' },
        { label: 'العنوان', key: 'title', type: 'text', class: 'text-right' },
        { label: 'النوع', key: 'type', type: 'transactions', class: 'text-right' }
        // {
        //   label: 'التحكم',
        //   key: 'actions',
        //   class: 'text-right',
        //   type: 'actions',
        //   actions: [
        //     {
        //       text: 'edit',
        //       icon: 'ri-ball-pen-fill',
        //       color: 'info',
        //       ifNavigate: true,
        //       routePath: 'edit-video/:id'
        //     },
        //     {
        //       text: 'Delete',
        //       icon: 'ri-delete-bin-line',
        //       color: 'danger',
        //       url: 'admin/galleries',
        //       baseUrl: SFZ_API_LINK,
        //       titleHeader: 'منتج',
        //       question: 'هل متأكد أنك تريد مسح هذا العنصر',
        //       textContent: 'name',
        //       textDeleteButton: 'بالتأكيد, مسح',
        //       textCancelButton: 'الغاء',
        //       showAlert: true
        //     }
        //   ]
        // }
      ]
    }
  }
}
</script>
<style>

</style>
